.loading {
    display: flex;
    .ant-spin-fullscreen {
        z-index: 9999;
        .ant-spin {
            z-index: 9999;
        }
    }
   
    .loading-content {
        width: 400px;
        .loading-message {
            margin: auto;
            width: 100px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }


        .ant-progress-text {
            color: white;
        }
    }

}