.volume-controller {
    .ant-popover {
        top: -113px !important;
        left: -5px !important;
        width: 32px;
        height: 113px;
        .ant-popover-content {
            height: 100%;
            .ant-popover-inner {
                height: calc(100% - 32px);
                padding: 16px 0;
                border-radius: 0;
                .ant-popover-inner-content {
                    height: 100%;
                }
            }
            
         }
       
        .ant-popover-arrow {
            visibility: hidden;
        }
    }

}