@font-face {
  font-family: "iconfont"; /* Project id 4507246 */
  src: url('iconfont.woff2?t=1724838944821') format('woff2'),
       url('iconfont.woff?t=1724838944821') format('woff'),
       url('iconfont.ttf?t=1724838944821') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ai-music:before {
  content: "\e600";
}

.icon-upload:before {
  content: "\e90b";
}

.icon-repeat:before {
  content: "\e878";
}

.icon-repeat-once:before {
  content: "\e879";
}

.icon-shuffle-variant:before {
  content: "\e8a0";
}

.icon-plus:before {
  content: "\e853";
}

.icon-music-circle:before {
  content: "\e805";
}

.icon-playlist-plus:before {
  content: "\e851";
}

.icon-delete:before {
  content: "\e62a";
}

.icon-loading:before {
  content: "\e7f1";
}

.icon-pause:before {
  content: "\e7fe";
}

.icon-caret-right:before {
  content: "\e8ec";
}

.icon-step-backward:before {
  content: "\e8ef";
}

.icon-step-forward:before {
  content: "\e8f0";
}

.icon-pause-circle-outline:before {
  content: "\e837";
}

.icon-play-circle-outline:before {
  content: "\e84f";
}

.icon-playlist-play:before {
  content: "\e850";
}

.icon-skip-next-circle-outline:before {
  content: "\e8ac";
}

.icon-skip-previous-circle-outline:before {
  content: "\e8ad";
}

.icon-volume-high:before {
  content: "\e925";
}

.icon-volume-off:before {
  content: "\e926";
}

