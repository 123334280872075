.app {
    .main-component {
        height: 100vh;
    }
    .hidden-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    #p5-container {
        width: 100%;
        height: 100%;
    }
}