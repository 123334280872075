.progress-bar {
    display: flex;
    align-items: center;
    font-size: 12px;
    height: 100%;
    margin-bottom: 10px;

    .ant-slider-rail, .ant-slider-track {
        height: 8px;
    }
    .time {
        margin-left: 14px;
        flex-shrink: 0;
        color: #797979;
        span {
            color: #a1a1a1;
        }
    }
}