.play-list-adder-modal {
    .title {
        color: #E0E0E0;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 24px;
    }
    .ant-segmented {
        background-color: #252525;
        margin-bottom: 16px;
        .ant-segmented-item {
            color: #A0A0A0 !important;
            &.ant-segmented-item-selected {
                color: #FFFFFF !important;
                background-color: #333333;
            }
            &:hover {
                color: #FFFFFF !important;;
            }
        }
        .ant-segmented-thumb {
            background-color: #333333;
        }
    }
    .ant-upload {
        width: 100%;
    }
    .ant-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgb(54, 48, 48);
        &:hover {
            background-color: rgb(250 247 245 / 30%) !important;
        }
    }



}